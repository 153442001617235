.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: 334499;
  border-radius: .3rem;  
}
.mr {
  margin-right: .5rem
}

.download {
  color: white;
  text-decoration: none;
}